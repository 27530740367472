<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 17" fill="none">
    <path d="M9.17085 0.5C8.84774 0.5 8.5855 0.762239 8.5855 1.08536V7.32914C8.5855 7.65226 8.84774 7.9145 9.17085 7.9145H15.4146C15.7378 7.9145 16 7.65226 16 7.32914C16 3.56375 12.9362 0.5 9.17085 0.5ZM6.82791 2.84142C2.7152 2.8422 -0.560061 6.49749 0.0803173 10.73C0.520504 13.6404 2.85961 15.9795 5.77 16.4197C10.0025 17.0601 13.6578 13.7848 13.6586 9.67209C13.6586 9.34897 13.3963 9.08521 13.0732 9.08521H8.19526C7.76405 9.08521 7.41479 8.73595 7.41479 8.30474V3.42678C7.41479 3.10366 7.15102 2.84142 6.82791 2.84142Z"
    :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 17
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
